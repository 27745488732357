.AdmissionSchoolbg{

    background-image: url("..\/Photos\/Admissionbg.jpg");
    width: 100%;
    height: 55vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionHarleybg{

    background-image: url("..\/Photos\/HarleyCampus.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionAECHSbg{

    background-image: url("..\/Photos\/AECHSCampus.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionSeniorwestridgebg{

    background-image: url("..\/Photos\/SeniorWestridgeCampus.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }


  .AdmissionChakribg{

    background-image: url("..\/Photos\/ChakriCampus.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }


  .Admissionjuniorwestridgebg{

    background-image: url("..\/Photos\/JuniorWestridge.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionGolraMorbg{

    background-image: url("..\/Photos\/GolraMor.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }


  .AdmissionPWDbg{

    background-image: url("..\/Photos\/PWDcampus.JPG");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionPeshawarRoadbg{

    background-image: url("..\/Photos\/skanspeshawarroad.png");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }
  .AdmissionF8bg{

    background-image: url("..\/Photos\/F8Campus.jpg");
    width: 100%;
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }


  .AdmissionSTbg{

    background-image: url("..\/Photos\/STCAMPUS.png");
    width: 100%;
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionPeshawarroadbg{

    background-image: url("..\/Photos\/bgpeshawarroad.jpeg");
    width: 100%;
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }

  .AdmissionRARbg{

    background-image: url("..\/Photos\/RAR\ campus.jpeg");
    width: 100%;
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  
    position: relative;
    overflow: hidden;
  
  }
  
  .filter15 {

    position: absolute;
    padding-top: 55%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
  }
  
  .filter15 h1 {
    font-size: 60px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 3px;
  }
  
  @media (max-width: 450px) {


    .filter15 h1 {
      font-size: 40px;
  
  }
    }





























  :root {
    --purple: #003478;
    --aqua: #b4fee7;
    --violet: #277dec;
    --fuchsia: #fd49a0;
    --white: #efefef;
    --black: #222;
    --trueBlack: #000;
  }
  
  
  

  
  .cards {
    display: grid;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .cards_item {
    display: flex;
  }
  
  .card_image {
    display: flex;
    height: 100%;
    box-shadow: 0 70px 150px 0 var(--violet);
  }
  
  .card_image img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .cardschool {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: #003478;
    border-radius: 5px 25px 5px 50px;
    transition: transform 0.1s linear, box-shadow 0.2s;
  }
  
  .card_text:focus,
  .cardschool:focus {
    outline: 2px dashed var(--aqua);
  }
  
  .cardschool:focus,
  .cardschool:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.2);
  }
  
  .card_content {
    padding: 0.5rem 1rem 1rem;
    color: var(--white);
  }
  
  .card_title {
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: auto;
    color: var(--black);
    padding: 0.5rem;
    border-radius: 5px 0 0 5px;
    transform: rotate(-3.3deg);
    transform-origin: left top;
    font-family: Georgia, Times, serif;
    font-weight: 600;
    font-size: 1.325rem;

    overflow: hidden;
    z-index: 1;
    color: white;
    background-color: #003478;
    animation: 0s 0s fly-in 0 reverse both;
  }
  
  @media (min-width: 535px) {
    .card_title {
      animation: 0.5s 0.25s fly-out 1 both;
    }
  }
  
  .cardschool:focus .card_title,
  .cardschool:hover .card_title {
    animation: 0.5s ease-in 0s fly-in 1 both;
  }
  
  .card_text {
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica,
      Helvetica Neue, Arial, sans-serif;
    line-height: 1.5;
    text-size-adjust: 0.2px;
    padding: 0 1rem;
  }
  
  .card_text p:first-of-type:first-letter {
    font-size: 1.8em;
    font-family: Georgia, Times, serif;
    margin-right: 0.05em;
  }
  
  @media (min-width: 480px) {
    .card_text {
      overflow: auto;
      max-height: 20rem;
      scrollbar-width: thin;
      scrollbar-color: var(--aqua) var(--violet);
    }
  
    .card_text::-webkit-scrollbar {
      width: 12px;
    }
  
    .card_text::-webkit-scrollbar-track {
      background: var(--violet);
    }
  
    .card_text::-webkit-scrollbar-thumb {
      background-color: var(--aqua);
    }
  }
  
  .card_text strong {
    color: var(--aqua);
  }
  
  .upcharge {
    position: relative;
    font-weight: 600;
    background-color: var(--violet);
    /* background-color: #003478; */
    padding: 0.5rem 0.75rem;
    color: var(--trueBlack);
    border-radius: 0 10px;
    z-index: 0;
    overflow: hidden;
  }
  
  .upcharge::after,
  .upcharge::before {
    content: "+";
    display: block;
    line-height: 0;
    font-size: 3rem;
    position: absolute;

    color: #003478;
    z-index: -1;
    opacity: 0.3;
  }
  
  .upcharge::before {
    left: 0;
    top: 0.5rem;
  }
  
  .upcharge::after {
    right: 0;
    bottom: 1.25rem;
  }
  
  .note {
    display: block;
    text-align: center;
    padding: 0.5rem;
    font-weight: 900;
    background-image: linear-gradient(
      -45deg,
      transparent 10%,
      var(--aqua) 19.5%,
      var(--aqua) 100%,
      transparent 100.5%
    );
    color: var(--black);
    font-size: 1.3em;
    font-style: italic;
    margin-top: 1rem;
  }
  
  @keyframes fly-in {
    0% {
      top: 0;
      right: 0;
      font-size: 1.325rem;
    }
  
    25% {
      top: 0;
      right: -200%;
      font-size: 1.325rem;
    }
  
    26% {
      font-size: 2rem;
    }
  
    100% {
      top: 2rem;
      right: 0;
      font-size: 2rem;
    }
  }
  
  @keyframes fly-out {
    0% {
      top: 2rem;
      right: 0;
      font-size: 2rem;
    }
  
    50% {
      top: 0;
      right: -200%;
      font-size: 1.325rem;
    }
  
    100% {
      top: 0;
      right: 0;
      font-size: 1.325rem;
    }
  }
  