


.bgfronthome1{
  position: relative;
  overflow: hidden;
  background-image: url("./..\/Photos\/home_2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 75vh;
}

.imgfronthome1 {
  -webkit-mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
  mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
  -webkit-mask-size: 600%;
  mask-size: 600%;
  -webkit-mask-position: 0;
  mask-position: 0;
}

.imgfronthome1:hover {
  transition: mask-position 2s ease,-webkit-mask-position 2s ease;
  -webkit-mask-position: 120%;
  mask-position: 120%;
  opacity: 1;
}

.bgfronthome2{
  position: relative;
    overflow: hidden;
  background-image: url("./..\/Photos\/home_1.webp");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  height: 75vh;

}


.arrow {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}

.filterhomeup{
  
  position: absolute;
  padding-top: 70%;
  padding-bottom: 70%;
  background-color: rgba(2, 2, 150, 0.6);
  top:50%;
  transform: translate(0%, -50%);
}
.image-layer {
  position: relative;
  overflow: hidden;


  height: 500px;
  z-index: 0;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;

  z-index: 0;
  overflow: hidden;
}




.text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, 0%);
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 70px 300px;
  border-radius: 5px;
  padding-inline: 300px;
  font-size: 24px;
  font-weight: bold;
  z-index: 2;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }



  50% {
    transform: scale(1.2);
  }


  100% {
    transform: scale(1);
  }

}

.image-0 {
  animation: zoomIn 8s linear infinite;
}

.image-1 {
  animation: zoomIn 8s linear infinite;
}

.image-2 {
  animation: zoomIn 8s linear infinite;
}

.image-3 {
  animation: zoomIn 8s linear infinite;
}


.bg-image {
  position: relative;
  height: 490px;
  width: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("..\/Photos\/sideslider.png");
  box-shadow: 3px 3px 1px #f2f5f9;
  -webkit-box-shadow: 3px 3px 1px #f2f5f9;
  -moz-box-shadow: 3px 3px 1px #f2f5f9;
  z-index: 0;
}

.cardside1 {
  border-radius: 60% 40% 40% 20%;
}

.feature-one__single-inner {
  position: relative;
  display: block;

  overflow: hidden;

}

.feature-one__icon {
  position: absolute;
  left: -40px;
  top: -90px;
  border-radius: 50%;
  background-image: linear-gradient(0deg, var(--insur-base, #003a87) 0%, var(--insur-primary, #0ce0ff) 100%);
  width: 197px;
  height: 197px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-one__button-color{
  background-image: linear-gradient(100deg, var(--insur-base, #1266d4) 50%, var(--insur-primary, #0ce0ff) 100%);
  color:white;
  font-weight:bold;
  border: none;
  transform: scaleX(1);
  transition: all .4s ease;

  
}
.feature-one__button-color:hover{

  transform: scale(1.06);
  color: white;
  -webkit-text-stroke-color: white;
}

.cardtext1 {

  padding-inline: 50px;
  padding-block-start: 100px;
  width: 100%;
  overflow-y: hidden;

}

.fontsmall {
  font-size: smaller;
}

.fontmedium {
  font-size: small;
}

.overflow-hidden {
  overflow: hidden;
}



.feature-one__count:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  -webkit-text-stroke: 1px #e0e4e8;
  font-size: 60px;
  line-height: 45px;
  font-weight: 700;
  counter-increment: count;
  content: "01";
  letter-spacing: var(--insur-letter-spacing);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-one__count {
  position: absolute;
  top: 30px;
  right: 40px;
  height: 50px;
  width: 100px;
  text-align: center;
  overflow: hidden;
}





.feature-two__count:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  -webkit-text-stroke: 1px #e0e4e8;
  font-size: 60px;
  line-height: 45px;
  font-weight: 700;
  counter-increment: count;
  content: "02";
  letter-spacing: var(--insur-letter-spacing);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-two__count {
  position: absolute;
  top: 30px;
  right: 40px;
  height: 50px;
  width: 100px;
  text-align: center;
  overflow: hidden;
}




.feature-three__count:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  -webkit-text-stroke: 1px #e0e4e8;
  font-size: 60px;
  line-height: 45px;
  font-weight: 700;
  counter-increment: count;
  content: "03";
  letter-spacing: var(--insur-letter-spacing);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-three__count {
  position: absolute;
  top: 30px;
  right: 40px;
  height: 50px;
  width: 100px;
  text-align: center;
  overflow: hidden;
}



.feature-one__icon span {
  font-size: 50px;
  color: var(--insur-white);
  position: relative;
  display: inline-block;
  top: 100px;
  color: white;
  left: 70px;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cta_bg {
  background-image: url("..\/Photos\/cta_bg.png");
}

.bgcounter {
  background-image: url("..\/Photos\/bgcounter.png");
}

.about-one__experience {
  position: absolute;
  bottom: 0px;
  left: 125px;
  display: flex;
  align-items: center;
  translate: calc(50%, 50%);

 
  padding: 10px;

}



.about-one {
  position: relative;
  display: block;
  overflow-y: hidden;


}

.blackhover {
  background-color: white;
  transition: all ease .3s;

  scale: 1;
}

.blackhover:hover {
  background-color: #003a87;
  color: white;
  opacity: 1;
  scale: 1.1;
}

.borderdash {
  border-style: dashed;
}







.tech-slideshow {
  height: 200px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  font-size: 10px;
  /* THIS IS A HACK TO REMOVE THE "WHITESPACE" BETWEEN IMAGES.
                  YOU COULD ALSO PUT ALL OF THE IMAGES ON THE SAME LINE
                  (eg: <img src=""><img src=""><img src=""> 
                  with no spaces or line-breaks),
                  BUT THAT MAKES THE CODE LESS READABLE SO I'M DOING THIS
                  FOR THE SAKE OF CREATING THIS EXAMPLE FOR YOU */
}

.mover-1 {
  height: 200px;
  width: max-content;

  position: absolute;
  overflow-x: hidden;


  animation: 60s moveSlideshow linear infinite;
}

.mover-1 img {
  display: inline-block;
  vertical-align: middle;
  width: 150px;
  margin: 20px;
}

@keyframes moveSlideshow {
  0%{
    transform: translateX(0);
  }
  100% {
    transform: translateX(-3000px);
  }
}

.text-justify {
  text-align: justify;
}





.h12 {
  position: relative;
  margin: 0 auto 20px;
  padding: 10px 40px;
  text-align: center;
  background-color: #875e46;
}

.h12::before,
.h12::after {
  content: '';
  width: 80px;
  height: 100%;
  background-color: #724b34;

  /* position ribbon ends behind and slightly lower */
  position: absolute;
  z-index: -1;
  top: 20px;

  /* clip ribbon end shape */
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%);

  /* draw and position the folded ribbon bit */
  background-image: linear-gradient(45deg, transparent 50%, #5d3922 50%);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.h12::before {
  left: -60px;
}

.h12::after {
  right: -60px;
  transform: scaleX(-1);
  /* flip horizontally */
}

/* everything below is for demo appearances and not important to the concept */



@media (max-width: 400px) {
  .h12 {
    font-size: 1.5rem;
  }
}





.sample {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  height: 200px;
  position: relative;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);


}

.textcontent {
  padding-top: 80px;
  display: contents;

}

.ribbon {
  position: absolute;
  left: var(--left, 10px);
  top: var(--top, -3px);
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
}

.ribbon>.content {
  color: white;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 400;
  background: var(--color, #08005e) linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.25) 100%);
  padding: 8px 2px 8px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
  width: var(--width, 50px);
  min-height: var(--height, 36px);
  transition: clip-path 1s, padding 1s;
}

.ribbon.down .content {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
}





























.tab_menu {
  width: 100%;
  height: 110px;
  background: #fff;
  float: left;
  border-radius: 10px;
  margin-top: -50px;
  position: relative;
  z-index: 9;
  box-shadow: 0px 0 14px 0 rgba(0,0,0,.3);
  overflow: hidden;
  padding: 0 30px;
}
.tab_menu ul {
  width: 950px;
}
.tab_menu {
  overflow-x: scroll;
}

.tab_menu ul li {
  width: 14.25%;
  float: left;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  list-style: none;
}
@media (max-width: 767px){
  .tab_menu {
    overflow-x: scroll!important;
}
}

.anchortag1 .btn{
  text-decoration: none!important;
  outline: none!important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.anchortag1 {
  color: #1f1f1f;
  text-decoration: none!important;
  outline: none!important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.tab_menu ul li span {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  float: left;
}
span.icon img {
  height: 35px;
}

.tab_menu ul li:nth-child(7), .tab_menu ul li:nth-child(5) {
  background: #002147;
  transform: skew(-30deg);
  color: #fff;
}
.tab_menu ul li:nth-child(7) a, .tab_menu ul li:nth-child(5) a {
  transform: skew(30deg);
  float: left;
  color: #fff;
}
.tab_menu ul li:nth-child(6) {
  background: #d4a81e;
  transform: skew(-30deg);
  color: #fff;
}
.tab_menu ul li:nth-child(6) a {
  transform: skew(30deg);
  float: left;
  color: #fff;
}








/* .carousel-item{

  height: 500px;
}

.carousel-item img{
  position: relative;
  top: -80px;
  
} */
@media screen and (max-width:490px){

  .about-one__experience {
    left:100px
  }
  
}

@media screen and (max-width:980px){

  .about-one__experience {
    left:190px
  }
  
}

@media screen and (max-width:767px){

  .about-one__experience {
    left:120px
  }
  
}

@media screen and (max-width:480px){

  .about-one__experience {
    left:100px
  }
  
}

@media screen and (max-width:450px){

  .about-one__experience {
    left:80px
  }
  
}
@media screen and (max-width:400px){

  .about-one__experience {
    left:60px;
   
  }
  
}

@media screen and (max-width:350px){

  .about-one__experience {
    left:40px;
   
  }
  
}




/* ANchor home top hover effect */




.link {
  height: 40px;
  align-items: center;
  /* color: #000; */
  text-decoration: none;
  display: flex;
}

/* Hide extra text */
.mask {
  position: relative;
  padding: 0;
  height: 40px;


  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.link-container {
  transition: transform 0.4s ease;
}

.title {
  display: block;

  /*  Set same font-size and line height  */
  /* font-size: 20px; */
  line-height: 40px;

  transition: transform 0.4s ease;
}

.link-title1 {
  transform-origin: right center;
}

.link-title2 {
  transform-origin: left center;
  transform: rotate(20deg);
}

.link-icon {
  position: relative;
  width: 50px;
  height: 50px;
  background: #f8f8ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.icon {
  display: block;
  position: absolute;
  transition: transform 0.4s ease;
}

.icon:nth-child(2) {
  transform: translate(-40px);
}

/* Hover Action*/

/* Move up two texts (20px = font-size) */
.link:hover .link-container {
  transform: translateY(-40px);
}

/* Rotate texts a little bit */
.link:hover .link-title1 {
  transform: rotate(20deg);
}

.link:hover .link-title2 {
  transform: rotate(0);
}

/* Move arrows to right */
.link:hover .icon:first-child {
  transform: translate(40px);
}

.link:hover .icon:nth-child(2) {
  transform: translate(0px);
}
